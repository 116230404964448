/* eslint-disable react/jsx-no-undef */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prefer-const */
/* eslint-disable*/

/**PS_NO_AP_01 - PS_NO_AP_03
 * The required hooks and the racstrap elements are imported
 */
import {
  RACButton,
  Grid,
  CircularProgress,
  RACCOLOR,
  makeStyles,
  RACModalCard,
  Typography,
  RACTableRow,
  RACTableCell,
  RACTable,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';

import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { useHistory } from "react-router";

import { customerStyles } from "../../stylesJS/customerStyles";
import { agreementContext } from "../context/PaymentInformationContext";
import React, { useContext, useState, useRef } from "react";
import moment from "moment";
import SecondFactor from "../../racstrapComponents/microfrontends/TwoFactorPopup";
import {
  b64toBlob,
  getContentType,
  getCustDetail,
} from '../../utils/utils';
import {
  GenerateAgreementDoc,
  ProcessPaymentAPI,
  getDocument,
  storeException,
  postAgreementActivity,
  UnEnrollAutoPay,
} from '../../api/user';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as FailIcon } from '../../assets/images/contest-close.svg';
import { ReactComponent as TransactionSuccessIcon } from '../../../src/assets/images/success-icon-print.svg';
import CustomerSignature from '../../racstrapComponents/microfrontends/CustomerSignature';
import StoreException from './StoreException';
import { LocationParams, ParamType } from '../interface/commonInterface'
import CONSTANTS from '../constants/constant';
import { AgreementDetails, CardDetailsInterface, Customer } from '../interface/paymentInfoInterface';
import { AgreementPaymentEntity, CardDetailsEntity, ClubPaymentEntity, CustomerSignFileds, ExceptionTypeArray, ExchangeAgreementArray, FinalResultArray, FreeTimePopupFlag, GetDocumentResponse, MiscPaymentEntity, PaymentAPIResult, PlaceOrderDetails, ReceiptArrayUrl, ReceiptStatus, ReceiptStatusInfo, ReceiptUrl, StoreExceptionPayload, TakePaymentRequest, TenderDetailsEntity, TransferSuspenseEntity } from '../interface/acceptPaymentInterface';
import { acceptPaymentStyles } from '../../stylesJS/acceptPaymentStyles';
import { CreatePaymentInfoForPayload } from '../utils/acceptPaymentUtills';
import Autopay from './HandleAutopay';
import { AppRoute } from '../../config/route-config';

export function AcceptPayment() {
  /**PS_NO_AP_08 - PS_NO_AP_10
   * The required state variabel declaration and the destructuring of context variable is done.
   */
  const classes = acceptPaymentStyles();
  const classes2 = takePaymentStyles();
  const classesTwo = customerStyles();
  const history = useHistory();
  const { customerId, agreementId } = useParams<ParamType>();
  const {
    agreementDetails,
    storeInfo,
    customerInfo,
    multiStoreSelected,
    paymentDisable,
    cappedFreeTimeDetails,
    coWorkerDetails,
    featureFlags,
    modalCardEnable,
    setModalCardEnable,
    paymentOrigin,
  } = useContext(agreementContext);
  const [openSuspenseWithdraw, setOpenSuspenseWithdraw] = useState<boolean>(false);
  const [txtChange, settxtChange] = useState<boolean>(false);
  const [sessionExpiredPopupFlag, setSessionExpiredPopupFlag] =
    useState<boolean>(false);
  const [racExchangeAutoPayPopup, setRacExchangeAutoPayPopup] = useState(false);
  const [exceededFreeTime, setExceededFreeTime] = useState<number>(0);
  const [secondFactorOpen, setSecondFactorOpen] = useState<boolean>(false);
  // const [TwoFactorCompleted, setTwoFactorCompleted] = useState<any>(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState<boolean>(false);
  const [cofPopup, setcofPopup] = useState<boolean>(false);
  const [freeTimeAuthPopup, setFreeTimeAuthPopup] = useState<FreeTimePopupFlag>({
    popup: false,
    secondFactor: false,
  });
  const [viewReceiptPopup, setviewReceiptPopup] = useState<boolean>(false);
  const [extIndex, setextIndex] = useState<number>(CONSTANTS.ZERO_NUMBER);
  const [isAmountMismatch, setIsAmountMismatch] = useState<boolean>(false);
  const [transactionFail, setTransactionFail] = useState<boolean>(false);
  const [load, setload] = useState<boolean>(false);
  const [receiptID, setReceiptID] = useState<string>(CONSTANTS.EMPTY_STRING);
  const [transactionResultArray, settransactionResultArray] = useState<PaymentAPIResult[]>([]);
  const [finalPaymentResult, setFinalPaymentResult] = useState<FinalResultArray[]>([] as FinalResultArray[]);
  const [initialReceiptStatus, setInitialReceiptStatus] = useState<ReceiptStatus[]>();
  let [receiptResultArray, setreceiptResultArray] = useState<ReceiptStatusInfo>({} as ReceiptStatusInfo);
  const [pifAgreementID, setPifAgreementID] = useState<string>(CONSTANTS.EMPTY_STRING);
  const [redirectToAccountManagement, setRedirectToAccountManagement] = useState<boolean>(false);
  const [showReceiptStatus, setShowReceiptStatus] = useState<boolean>(false);
  const [showPaymentStatus, setShowPaymentStatus] = useState<boolean>(false);
  const [customerSignaturePopup, setcustomerSignaturePopup] = useState<boolean>(false);
  const [customerSignUsingField, setCustomerSignUsingField] = useState<CustomerSignFileds>({
    field: CONSTANTS.EMPTY_STRING,
    moduleName: CONSTANTS.EMPTY_STRING,
    documentType: CONSTANTS.EMPTY_STRING,
  });
  const [cofIndex, setcofIndex] = useState<number>(CONSTANTS.ZERO_NUMBER);
  const [viewCOFPopup, setviewCOFPopup] = useState<boolean>(false);
  const [viewExtPopup, setviewExtPopup] = useState<boolean>(false);
  const [recpt, setrecpt] = useState<boolean>(false);
  const [clubSignFlow, setclubSignFlow] = useState<boolean>(false);
  const [tran, settran] = useState<boolean>(false);
  const [openCommitmentConfirmation, setopenCommitmentConfirmation] = useState<boolean>(false);
  const [cardPaymentFailedMessage, setCardPaymentFailedMessage] = useState<string>('');
  let [exceptionTypeArr, setExceptionTypeArr] = useState<ExceptionTypeArray[]>([]);
  let [storeExceptionRequest, setStoreExceptionRequest] = useState<any>([]);
  let [StoreExceptionEnable, setStoreExceptionEnable] = useState<boolean>(false);
  const [proceedButtonLoader, setProceedButtonLoader] = useState<boolean>(false);
  let [exchangedAgreementArray, setExchangeAgreementArray] = useState<ExchangeAgreementArray[]>([]);
  const [autoPayConfirmationPopup, setAutoPayConfirmationPopup] = useState<boolean>(false);
  const collectStoreNumber: string[] = [];
  const urlParams = new URLSearchParams(window.location.search);
  const retentionType = urlParams.get(CONSTANTS.URLPARAMS_CRTYPE);
  const location = useLocation<LocationParams>();
  const storeNumber = String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER));
  let receptFailedAttempts = CONSTANTS.ZERO_NUMBER;
  const [choosenReceiptIndex, setChoosenReceiptIndex] = useState<number>(CONSTANTS.ZERO_NUMBER)
  // : number | undefined = CONSTANTS.ZERO_NUMBER;
  const eventFrame = useRef<HTMLIFrameElement>(null);
  const exchangeAgreementId: string | null = urlParams.get('exchangeAgreementId');

  /**PS_NO_AP_21
   * The sessionReload() method is used to reload the payment page
   * if the strore number is null or undefined
   */
  const sessionReload = () => {

    if (
      storeNumber == null ||
      storeNumber == CONSTANTS.NULL_STRING ||
      storeNumber == CONSTANTS.UNDEFINED_STRING ||
      storeNumber == undefined
    ) {
      window.sessionStorage.setItem(CONSTANTS.STORE_NUMBER, CONSTANTS.EMPTY_STRING);
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  /**PS_NO_AP_22- PS_NO_AP_23
   * The validateCappedFreeTime() used to check whether the customer takes payment by 
   * applying capped free time.
   */
  const validateCappedFreeTime = () => {
    if(featureFlags?.CappedFreeTimeEnabled == CONSTANTS.ONE_STRING)
      {const pendingAgr = agreementDetails.filter(
      (x) => x.numberOfPaymentsMade == CONSTANTS.ZERO_NUMBER && x.selected && x.storeNumber == storeNumber
    );
    const selectedAgreements = agreementDetails.filter((x) => x.selected && x.storeNumber == storeNumber);
    let totalExtensionAmount =
      pendingAgr.length > CONSTANTS.ZERO_NUMBER
        ? pendingAgr?.reduce(
          (total, obj) => total + Number(obj.extensionAmount || CONSTANTS.ZERO_NUMBER), CONSTANTS.ZERO_NUMBER
        )
        : CONSTANTS.ZERO_NUMBER;
   
    let selectedAgrTotalExtentionAmount =
      selectedAgreements.length > CONSTANTS.ZERO_NUMBER
        ? selectedAgreements?.reduce(
          (total, obj) => total + Number(obj.freeTimeAmountUsed || CONSTANTS.ZERO_NUMBER), CONSTANTS.ZERO_NUMBER
        )
        : CONSTANTS.ZERO_NUMBER;
    let amountUsed = Number(cappedFreeTimeDetails?.originalStoreRemainingBalance) - Number(selectedAgrTotalExtentionAmount) < 0 ? Math.abs(Number(cappedFreeTimeDetails?.originalStoreRemainingBalance) - Number(selectedAgrTotalExtentionAmount)) : 0;
    let storeRemainingAmount = Number(cappedFreeTimeDetails?.originalStoreRemainingBalance) - Number(selectedAgrTotalExtentionAmount) < 0 ? CONSTANTS.ZREO_TWO_DECIMAL_STRING : (Number(cappedFreeTimeDetails?.originalStoreRemainingBalance) - Number(selectedAgrTotalExtentionAmount)).toFixed(2);
    // setCappedFreeTimeDetails({...cappedFreeTimeDetails,storeRemainingAmount:Number(storeRemainingAmount),amountUsed:selectedAgrTotalExtentionAmount});
    if (
      Number(totalExtensionAmount) > Number(storeRemainingAmount) &&
      pendingAgr.some((x) => moment(x.openDate).isBefore(moment(), 'day'))
    ) {
      setExceededFreeTime(
        amountUsed +
        (Number(storeRemainingAmount) <= CONSTANTS.ZERO_NUMBER
          ? totalExtensionAmount
          : Number(storeRemainingAmount) - Number(totalExtensionAmount))
      );
      setFreeTimeAuthPopup({
        ...freeTimeAuthPopup,
        popup: true,
        secondFactor: false,
      });
    } else {
      setExceededFreeTime(amountUsed)
      setSecondFactorOpen(true);
      // setModuleName('Accept Payment');
    }
  }else{
      setSecondFactorOpen(true);
    }
  };
  /**PS_NO_AP_17 - PS_NO_AP_21
   * The function acceptPaymentOnclick() method is invoked when the user click the accept payment button
   */
  const acceptPaymentOnclick = (flag:boolean) => {
    if (
      storeNumber != CONSTANTS.EMPTY_STRING &&
      storeNumber != CONSTANTS.NULL_STRING &&
      storeNumber != null &&
      storeNumber != CONSTANTS.UNDEFINED_STRING
    ) {
      const selectedAgr = agreementDetails.filter(
        (x) => x.selected && x.storeNumber == storeNumber
      );
      const totalSuspenseUseAmount = selectedAgr.reduce(
        (total, obj) => total + Number(obj.suspenseDetails.suspUse || CONSTANTS.ZERO_NUMBER),
        CONSTANTS.ZERO_NUMBER
      );
      const totalAmount = selectedAgr.reduce(
        (total, obj) => total + (!obj?.isReturnAgreement? Number(obj.totalDueAmount || CONSTANTS.ZERO_NUMBER):CONSTANTS.ZERO_NUMBER),
        CONSTANTS.ZERO_NUMBER
      );
      if (Number(totalSuspenseUseAmount) > Number(totalAmount) && flag) {
        setOpenSuspenseWithdraw(true);
      } else {
        if(featureFlags?.RACExchange == CONSTANTS.ONE_STRING)
        exchangeAgreementValidation();
      
        isExceptionProvided();
        validateCappedFreeTime();
      }
    } else {
      setSessionExpiredPopupFlag(true);
    }
  };

  /**PS_NO_AP_23
   * The freeTimeAuthPopupContent() component is used to display the popup if the free time exceeds the current store free time.
   */
  const freeTimeAuthPopupContent = () => {
    return (
      <Grid className={`${classes2.textCenter}`} data-testid='free_time_auth_popup'>
        <AlertIcon />
        <Typography
          className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup1}`}
        >
          Allocated free time for this store has been exceeded. Do you still
          want to proceed ?
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding}`}>
          <RACButton
            variant='outlined'
            color='primary'
            className={`${classesTwo.customerFloatleft}`}
            onClick={() => {
              setFreeTimeAuthPopup({ ...freeTimeAuthPopup, secondFactor: false, popup: false });
              setProceedButtonLoader(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            color='primary'
            variant='contained'
            data-testid='free_time_auth_popup_proceed'
            className={`${classesTwo.customerFloatright}`}
            onClick={() => {
              setFreeTimeAuthPopup({ ...freeTimeAuthPopup, secondFactor: true });
              //   setModuleName('CappedFreeTime')
              // setSecondFactorOpen(true);
              setProceedButtonLoader(true);
            }}
            loading={proceedButtonLoader}
          >
            Proceed
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /**PS_NO_AP_21
   * The buildSuspenseWithdraw() method id used to render the popup, if the customer uses the supence amount to pay the 
   * entire payment
   */
  const buildSuspenseWithdraw = () => {
    return (
      <Grid item className={classes.paymentmodal}>
        <Grid item md={12} className={classes.textcenter}>
          <Typography className='row justify-content-center popup-text' data-testId='overPayment_id'>
            Customer is withdrawing from Suspense / Waiting for Signature
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Grid className={classes.mt3}>
            <RACButton
              variant='contained'
              color='primary'
              className={classes.me2}
              onClick={() => {
                setOpenSuspenseWithdraw(false);
                acceptPaymentOnclick(false)
                // validateCappedFreeTime();
                //   setTwoFactorCompleted(false);
                //   setTwoFactorCancelClick(false);
                //   setSecondFactorOpen(true);
              }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**PS_NO_AP_21
   * The sessionExpiredReloadPopup() method used to render the session reload popup.
   */
  const sessionExpiredReloadPopup = () => {
    return (
      <div>
        <Grid className={classes.textcenter} style={{ marginBottom: '22px' }}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorSessionFunction} data-testId='session_expiry'>
            Your session has expired
          </Typography>
          <Typography className={classes.popUpTxtStyleSession} data-testId='Please_login'>
            Please login again
          </Typography>
        </Grid>
        <Grid className={classes.textcenter} style={{ marginLeft: '3px' }}>
          <RACButton
            autoFocus
            variant='contained'
            color='primary'
            data-testid='session_ok_onclick'
            className={classes.me2}
            onClick={() => sessionReload()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  /**PS_NO_AP_96
  The invokeReceiptParallel() method is used to fetch the receipt by making
  */
  const invokeReceiptParallel = async (finalResult: FinalResultArray[]) => {

    const isFailed: GetDocumentResponse[] = [];
    const isPassed: GetDocumentResponse[] = [];
    let totalFileList: ReceiptStatus[] = [];
    settxtChange(true);
    await Promise.all(
      finalResult.map(async (el) => {
        if (!el.isPaymentFailed) {
          const resultOfDoct: GetDocumentResponse = await getDocument(el.receiptID, 'RCP');
          resultOfDoct.data.storeNumber = el.storeNumber;
          resultOfDoct.data.receiptID = el.receiptID;
          if (
            resultOfDoct.status == 200 &&
            Object.keys(resultOfDoct?.data).length > CONSTANTS.ONE_NUMBER &&
            resultOfDoct.data?.fileList[0]?.fileObject !== null
          ) {
            isPassed.push(resultOfDoct);
          } else {
            isFailed.push(resultOfDoct);
          }
        } else {
          totalFileList.push({
            documentURL: CONSTANTS.EMPTY_STRING,
            storeNumber: el.storeNumber,
            isPaymentFailed: true,
          });
        }
      })
    );

    if (isFailed.length && receptFailedAttempts < 10) {
      receptFailedAttempts = receptFailedAttempts + CONSTANTS.ONE_NUMBER;
      invokeReceiptParallel(finalResult);
    } else {
      const combinedData = [...isPassed, ...isFailed];
      combinedData.map((el) => {
        if (
          el.status == 200 &&
          Object.keys(el.data).length > CONSTANTS.ONE_NUMBER &&
          el.data.fileList[0].fileObject !== null
        ) {

          let cooRecord = el.data.fileList.filter((x) => x.docType === CONSTANTS.RECEIPT_TYPE_COOS);
          let receiptRecord = el.data.fileList.filter(
            (x) => x.docType === CONSTANTS.RECEIPT_TYPE_PRCT
          );
          let Extrecord = el.data.fileList.filter((x) => x.docType === CONSTANTS.RECEIPT_TYPE_EXTRF);
          if (el.data.fileList.length != CONSTANTS.ZERO_NUMBER) {
            if (receiptRecord.length != CONSTANTS.ZERO_NUMBER) {
              receiptRecord.map((file) => {
                const type = getContentType(file?.fileName);
                const formatedURL = URL.createObjectURL(
                  b64toBlob(file?.fileObject, type)
                );
                totalFileList.push({
                  documentType: CONSTANTS.RECEIPT_TYPE_PRCT,
                  documentURL: formatedURL,
                  receiptID: el.data.receiptID,
                  storeNumber: el.data.storeNumber ?? CONSTANTS.EMPTY_STRING,
                  isPaymentFailed: false,
                  isLoading: false,
                });
              })

            }
            if (cooRecord.length != CONSTANTS.ZERO_NUMBER) {
              cooRecord.map((file) => {
                const type = getContentType(file?.fileName);
                const formatedURL = URL.createObjectURL(
                  b64toBlob(file?.fileObject, type)
                );
                totalFileList.push({
                  documentType: CONSTANTS.RECEIPT_TYPE_COOS,
                  documentURL: formatedURL,
                  receiptID: el.data.receiptID,
                  storeNumber: el.data.storeNumber ?? CONSTANTS.EMPTY_STRING,
                  isPaymentFailed: false,
                  isLoading: false,
                });
              })
            }
            if (Extrecord.length != CONSTANTS.ZERO_NUMBER) {
              Extrecord.map((file) => {
                const type = getContentType(file?.fileName);
                const formatedURL = URL.createObjectURL(
                  b64toBlob(file?.fileObject, type)
                );
                totalFileList.push({
                  documentType: CONSTANTS.RECEIPT_TYPE_EXTRF,
                  documentURL: formatedURL,
                  receiptID: el.data.receiptID,
                  storeNumber: el.data.storeNumber ?? CONSTANTS.EMPTY_STRING,
                  isPaymentFailed: false,
                  isLoading: false,
                });
              })
            }
          }
        } else {
          totalFileList.push({
            documentType: CONSTANTS.EMPTY_STRING,
            documentURL: CONSTANTS.EMPTY_STRING,
            storeNumber: el.data.storeNumber ?? CONSTANTS.EMPTY_STRING,
            isPaymentFailed: false,
            isLoading: false,
          });
        }
      });
      receptFailedAttempts = CONSTANTS.ZERO_NUMBER;
    }
    if(totalFileList?.length > 0){
    totalFileList=totalFileList.sort((a, b) => {
      // Assuming receiptID is a string, use localeCompare for proper string comparison
      return Number(a?.receiptID??0) -Number(b?.receiptID??0);
    })}

    settxtChange(false);
    setreceiptResultArray({ ...receiptResultArray, totalFileList: totalFileList, cooArray: totalFileList.filter((x) => x.documentType == CONSTANTS.RECEIPT_TYPE_COOS), extentionArray: totalFileList.filter((x) => x.documentType == CONSTANTS.RECEIPT_TYPE_EXTRF) });

  };

  /** The groupAgreementsByStore() method is used to form the object which accumulate the agreements in array
  against the respective store number
  */
  const groupAgreementsByStore = (agreements: AgreementDetails[]) => {
    return agreements.reduce((group, agreement) => {
      const { storeNumber } = agreement;
      group[storeNumber] = group[storeNumber] || [];
      group[storeNumber].push(agreement);
      return group;
    }, {});
  };

  /**The createProcessOrderData() method is used to form the payload for the customerOrder prilim/initial payment 
  */
  const createProcessOrderData = (customerInfo: Customer) => {
    // const { provideEAFlag, provideAgrNumberPO, providePOAddressData, providePlaceOrderItemData } = customerInfo?.coPrelimDetails;
    const processOrderData: PlaceOrderDetails = {
      eaFlag: customerInfo?.coPrelimDetails?.provideEAFlag ?? CONSTANTS.EMPTY_STRING,
      agreementNumber: customerInfo?.coPrelimDetails?.provideAgrNumberPO ?? CONSTANTS.EMPTY_STRING,
    };

    if (customerInfo?.coPrelimDetails?.provideEAFlag == CONSTANTS.YES) {
      processOrderData.shippingAddress = customerInfo?.coPrelimDetails?.providePOAddressData;
      processOrderData.items = customerInfo?.coPrelimDetails?.providePlaceOrderItemData;
    }

    return processOrderData;

  }

  /** PS_NO_AP_29 -  The invokePaymentAPI() method is invoked once all the validation is completed */
  const invokePaymentAPI = async () => {

    let entireAcceptPayAPIArray: TakePaymentRequest[] = [];

    let processOrderData: PlaceOrderDetails;
    const selectedAgreements: AgreementDetails[] = agreementDetails.filter((x) => x.selected);
    const groupByStoreNumber = groupAgreementsByStore(selectedAgreements)
    const keys = Object.keys(groupByStoreNumber);

    if (customerInfo?.coPrelimDetails) {
      processOrderData = createProcessOrderData(customerInfo);
    }
    entireAcceptPayAPIArray = await Promise.all(keys.map(async (key) => {
      collectStoreNumber.push(groupByStoreNumber[key][0].storeNumber);
      return CreatePaymentInfoForPayload(groupByStoreNumber[key], processOrderData, key, undefined, { customerId, agreementDetails, customerInfo, multiStoreSelected, featureFlags, paymentOrigin, coWorkerDetails, storeInfo, exceededFreeTime, cappedFreeTimeDetails })
    }));

    try {
      const finalResult: FinalResultArray[] = await processPayments(entireAcceptPayAPIArray);
      setReceiptID(finalResult.filter((x) => x.storeNumber == storeNumber)[0]?.receiptID)
      handlePaymentResults(finalResult);

      if (storeExceptionRequest.length > CONSTANTS.ZERO_NUMBER && !finalResult.filter((x) => x.storeNumber == storeNumber)[0]?.isPaymentFailed) {
        const requestForStoreException = storeExceptionPayloadFormation(entireAcceptPayAPIArray[0], finalResult);
        storeException(requestForStoreException);
      }
    } catch (error) {
      console.error('Error in invokePaymentAPI:', error);
      setTransactionFail(true);
      setload(false);
    }

  };
  /**The processPayments() is invoked to make the api call for taking the payment */
  const processPayments = async (paymentInfoArray: TakePaymentRequest[]) => {

    const results = await Promise.all(paymentInfoArray.map(async (paymentInfo: TakePaymentRequest, index: number) => {
      const result = await ProcessPaymentAPI(paymentInfo);
      return handlePaymentResult(result, paymentInfo, index);
    }));
    return results.flat()
  };

  /**The handlePaymentResult() method is used to handle the process payment responce once the payment completed*/
  const handlePaymentResult = (result, paymentInfo: TakePaymentRequest, index: number) => {

    if (result.status === 200) {
      if (!multiStoreSelected)
        settran(true);

      handlePostAgreementActivity(paymentInfo);
      if (exchangedAgreementArray.length > 0 && featureFlags?.RACExchange == CONSTANTS.ONE_STRING)
        unEnrollAutoPay();

      return handleSuccessfulPayment(result, index);
    } else {
      return handleFailedPayment(result, index);
    }
  };

  /** The handleSuccessfulPayment() method is used to form the array containing objec with receiptId along with the store
   to show the successfull payment status in the popup if its different stor payment is done */
  const handleSuccessfulPayment = (result, index: number) => {

    return result.data.receiptInfo.map(rcpel => ({
      storeNumber: collectStoreNumber[index],
      receiptID: rcpel.receiptId,
      isPaymentFailed: false,
    }));

  };


  const handleFailedPayment = (result, index: number) => {
    if (!multiStoreSelected) {
      handleSingleStoreFailure(result);
    }
    return {
      storeNumber: collectStoreNumber[index],
      receiptID: CONSTANTS.EMPTY_STRING,
      isPaymentFailed: true,
    };
  };

  /**
   * The handleSingleStoreFailure() function is used to handle the payment failure while making the payment for single store
   */
  const handleSingleStoreFailure = (result) => {
    const storeErrorMessage = result?.data?.errors[0]?.error?.message;
    if (storeErrorMessage && storeErrorMessage.includes(CONSTANTS.AMOUNT_MISMATCH)) {

      setIsAmountMismatch(true);
    } else if (result.status == 400 && result.data?.errors[0]?.error?.serviceResponse?.Body?.StatusInfo?.StatusCodeDetail) {

      let declineCode = result?.data?.errors[0]?.error?.serviceResponse?.Body?.declineCode;
      const internalStatusCode = result?.data?.errors[0]?.error?.serviceResponse?.Body?.StatusInfo?.statusCode;
      if (internalStatusCode === 'DP') {
        declineCode = 712;
      }
      const cardDeclineMessage = CONSTANTS.CARD_DECLINE_MESSAGE[Number(declineCode)] || CONSTANTS.CARD_DECLINE_MESSAGE[13];
      setCardPaymentFailedMessage(cardDeclineMessage);
      setTransactionFail(true);


    }
    else {
      setTransactionFail(true);
      setCardPaymentFailedMessage(CONSTANTS.EMPTY_STRING);
    }
    // Handle decline codes if necessary
  };

  /**
   * The handlePaymentResults() method is triggered once the payment is taken successfully and the receipt is fetched 
   * and the status popup is shown based on the selected agreement store.
   * @param finalResult 
   */
  const handlePaymentResults = (finalResult: FinalResultArray[]) => {
    const receiptConditionData = finalResult.map(el => ({
      documentType: CONSTANTS.EMPTY_STRING,
      documentURL: CONSTANTS.EMPTY_STRING,
      storeNumber: el.storeNumber,
      receiptID: el.receiptID,
      isPaymentFailed: el.isPaymentFailed,
      isLoading: true,
    }))?.sort((a, b) => {
      return a.receiptID.localeCompare(b.receiptID);
    });
    receiptResultArray={ ...receiptResultArray, totalFileList: receiptConditionData }
    setreceiptResultArray(receiptResultArray);
    invokeReceiptParallel(finalResult);
    setInitialReceiptStatus(receiptConditionData);
    setFinalPaymentResult(finalResult);
    // setInitialReceiptStatus(receiptConditionData);
    // setreceiptResultArray(receiptConditionData);
   
    if (multiStoreSelected) {
      const paymentAPIResult = finalResult.map(el => ({
        storeNumber: el.storeNumber,
        transactionStatus: el.isPaymentFailed ? CONSTANTS.PAYMENT_FAILED : CONSTANTS.PAYMENT_PASSED,
      }));
      settransactionResultArray(paymentAPIResult);
      setShowPaymentStatus(true);
      setload(false);
    }
    else {
      // settran(true);
      setload(false);
    }


  };

  /**
   * The handlePostAgreementActivity() method is used to post the postAgreementActivity, it is done only the
   * retention EPO or the retention free time is done.
   * @param paymentInfo 
   */
  const handlePostAgreementActivity = async (paymentInfo: TakePaymentRequest) => {
    if (retentionType && paymentInfo?.paymentInfo && paymentInfo?.paymentInfo.length > CONSTANTS.ZERO_NUMBER && paymentInfo?.paymentInfo[0].agreementPayment) {
      const agreementIdRetention = location?.state[CONSTANTS.RETENTION_DATA] ? location?.state[CONSTANTS.RETENTION_DATA]?.agreementId : CONSTANTS.EMPTY_STRING;
      if (agreementIdRetention) {
        const today = new Date().toISOString().split('T')[0];

        const filterApplied = paymentInfo?.paymentInfo[0]?.agreementPayment?.filter(
          (el) =>
            el.agreementId == agreementIdRetention &&
            (el.freeTime !== null || el.epoPayment !== CONSTANTS.STATUS_YES)
        )??[];

        if (filterApplied.length) {
          const { agreementId, freeTime } =
            filterApplied[0];
          const activityPayload = {
            agreementActivities: [
              {
                activityDate: today,
                agreementId: agreementId,
                activityDescription:
                  freeTime !== null
                    ? CONSTANTS.RETENTION_FREETIME
                    : CONSTANTS.RETENTION_EPO,
                agreementActivityType:
                  freeTime !== null ? CONSTANTS.RETENTION_TYPE[0] : CONSTANTS.RETENTION_TYPE[1],
              },
            ],
          };

          await postAgreementActivity(activityPayload);
        }
      }
    }
  }

  if (redirectToAccountManagement) {
    const urlParams = new URLSearchParams(window.location.search);
    const retentionType = urlParams.get('CRTYPE');
    if (retentionType) {
      return (
        <Redirect
          to={{
            pathname: `/am/customer/${customerId}`,
            state: {
              ...(location?.state || {}),
              redirectSource: 'payment',
            },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: AppRoute.AccountManagement.replace(
              ':customerId',
              location?.state?.accountManagementCustomerId || customerId || ''
            ),
            state: {
              ...(location?.state || {}),
              redirectSource: 'payment',
            },
          }}
        />
      );
    }
  }

  /**
   * The customerDocGen() method is used to form the paylod make the api call to generate the document for getting sign from 
   * the during, club creation and the agreement convert into the note payment
   * @param options 
   */
  const customerDocGen = async (options: {
    isClub: boolean;
    agreementId?: number;
  }) => {
    const { isClub, agreementId } = options;

    const generateDocPayload = {
      identifier: isClub ? Number(customerId) : Number(agreementId),
      identifierType: isClub ? 'CUST' : CONSTANTS.AGR.toUpperCase(),
      signingUrlRequired: false,
      signers: [
        {
          customerId: String(customerId),
          customerName: `${customerInfo.customerDetails[0].firstName}${CONSTANTS.STRING_WITH_SINGLE_SPACE}${customerInfo.customerDetails[0].lastName}`,
          phoneNumber: String(customerInfo.customerDetails[0]?.phoneNumber),
          emailId: String(customerInfo.customerDetails[0].emailAddress),
          priority: CONSTANTS.ONE_NUMBER,
        },
      ],
      signatureType: CONSTANTS.ONE_NUMBER,
      isRemote: false,
      manualSignatureReasonId: null,
      documentType: isClub
        ? [CONSTANTS.CLUB_SMALL_CASE.toUpperCase()]
        : CONSTANTS.CUSTOMER_SIGNAETURE_INSTALLMENT_ARR,
      returnURL: window.location.origin,
    };

    await GenerateAgreementDoc(generateDocPayload);
    setclubSignFlow(true);
  };
  /**
   * The installmentAgrSignFlow() method is used to open the customer signaeture popup
   */
  const installmentAgrSignFlow = (installmentInfo: AgreementDetails[]) => {
    for (let i = 0; i < installmentInfo?.length; i++) {
      customerDocGen({ isClub: false, agreementId: Number(installmentInfo[i].agreementId) })
      setPifAgreementID(installmentInfo[i].agreementId);
      setCustomerSignUsingField({
        ...customerSignUsingField,
        field: CONSTANTS.AGREEMENT_DETAILS,
        documentType: CONSTANTS.CUSTOMER_SIGNAETURE_INSTALLMENT_ARR[0],
      });
      setcustomerSignaturePopup(true);
      setrecpt(false);
    }
  };

  /**
   * PS_NO_AP_102 - PS_NO_AP_106
   * The redirectionFunc() method is triggered when the ok button is clicked in the receipt popup,
   * this function helps to route the page based on the page logined.
   * @param flag 
   */
  const redirectionFunc = (flag: boolean) => {
    setShowReceiptStatus(false);
    const getUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const retentionType = urlParams.get(CONSTANTS.URLPARAMS_CRTYPE) ?? CONSTANTS.EMPTY_STRING;
    const retentionSource = urlParams.get(CONSTANTS.URLPARAMS_SRCTYPE) ?? CONSTANTS.EMPTY_STRING;
    if (Number(agreementId) > CONSTANTS.ZERO_NUMBER && getUrl.includes('/customerorder/initial/')) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      });
    } else if (
      Number(agreementId) > CONSTANTS.ZERO_NUMBER &&
      getUrl.includes('/customerorder/preliminary/')
    ) {
      history.push({
        pathname: `/agreement/customerorder/postatus/${customerId}/${agreementId}`,
      });
    } else if (Number(agreementId) > CONSTANTS.ZERO_NUMBER) {
      history.push({
        pathname: `/agreement/rental/delivery/${customerId}/${agreementId}`,
      });
    } else if (agreementDetails.some((x) => x.selected && x.isCommitmentTaken) && !flag) {
      setrecpt(false);
      setopenCommitmentConfirmation(true);
    } else if (location?.state?.redirectSource === CONSTANTS.AM) {
      setRedirectToAccountManagement(true);
    } else if (retentionType && retentionSource == undefined) {
      setRedirectToAccountManagement(true);
    } else if (retentionType && retentionSource == CONSTANTS.PAYMENT.toUpperCase()) {
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/${agreementId}`,
      });
      window.location.reload();
    } else {
      window.location.reload();

    }
  };


  const exchangeAgreementValidation = () => {
    const isAutoPayEnrolledParentAgreement = agreementDetails.filter(
      (x) => x.isExchanged
    );
    const exchangeAgreementArray = agreementDetails
      .filter((x) => x.selected && x.agreementStatus == CONSTANTS.AGREEMENT_STATUS_ACTIVE_PEND[1] && x.parentAgreementId)
      ?.reduce((acc: Array<{ agreementId: string; agreementNumber: string; childrenAgreementId: string; isAutoPayEnrolled: boolean; }>, x) => {
        const parentAgreement = isAutoPayEnrolledParentAgreement.find(
          (el) => el.agreementId == x.parentAgreementId
        );

        if (parentAgreement) {
          acc.push({
            agreementId: x.parentAgreementId ?? CONSTANTS.EMPTY_STRING,
            agreementNumber: parentAgreement.agreementNumber || CONSTANTS.EMPTY_STRING,
            childrenAgreementId: x.agreementId,
            isAutoPayEnrolled: parentAgreement.isAutopayEnroll ? true : false
          });
        }

        return acc;
      }, []);
    exchangedAgreementArray = exchangeAgreementArray
    setExchangeAgreementArray(exchangedAgreementArray);


  };

  async function unEnrollAutoPay() {
    let agreementArray: string[] = [];
    try {
      if (exchangedAgreementArray?.length) {
        exchangedAgreementArray?.forEach((element) => {
          if (element?.isAutoPayEnrolled)
            agreementArray.push(element?.agreementId?.toString());
        })
        if (agreementArray.length > 0) {
          UnEnrollAutoPay({
            autopayFlag: CONSTANTS.ZERO_NUMBER,
            agreementIds: agreementArray
          });
        }
      }
    }
    catch (e) {
      console.log(e, 'Error in Catch');
      //catch handle
    }
  }

  const openAutoPayPopup = () => {//RAC2

    const autoPayEnrolledParentAgreement = exchangedAgreementArray.filter((el) => el.isAutoPayEnrolled);
    return (
      <Grid className={`${classes2.textCenter}`}>
        <Typography className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup2}`}>
          Autopay will be un-enrolled for the Exchanged agreement {autoPayEnrolledParentAgreement?.length > 0 ? autoPayEnrolledParentAgreement?.map((element: ExchangeAgreementArray) => element?.agreementNumber)?.join(', ')?.length > 25 ? autoPayEnrolledParentAgreement?.map((element: ExchangeAgreementArray) => element?.agreementNumber)?.join(', ')?.slice(0, 20) + '...' : autoPayEnrolledParentAgreement?.map((element: ExchangeAgreementArray) => element?.agreementNumber)?.join(', ') : exchangeAgreementId}.
        </Typography>
        <Typography className={`${classesTwo.mt11} ${classesTwo.customerRacpopup3}`}>
          Does the customer want to enroll in autopay for the New Agreement?
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding} ${classesTwo.centerButton}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classesTwo.customerFloatleft} ${classesTwo.mr15px}`}
            onClick={() => {
              setAutoPayConfirmationPopup(false);
              history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
            }}
          >
            No
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classesTwo.customerFloatright}`}
            onClick={() => setRacExchangeAutoPayPopup(true)}
            loading={proceedButtonLoader}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    )
  }
  /**
   * PS_NO_AP_100 - PS_NO_AP_102
   * The ReceiptOk() method is invoked when the ok button is clicked in the receipt popup,
   * this function helps to route the page based on the page logined.
   */
  const ReceiptOk = () => {
    setrecpt(false);
    if (featureFlags.ActivateClub == CONSTANTS.ONE_STRING) {
      const clubInformation = agreementDetails.filter(
        (x: AgreementDetails) => x.agreementType == CONSTANTS.CLUB
      );
      const installmentInfo = agreementDetails.filter(
        (x: AgreementDetails) =>
          x.selected &&
          (x.agreementType == 'PIF Rent' ||
            (x.agreementType == CONSTANTS.EPO_RENT && x?.acceptEpoFlag == 0 && !(x?.isOverSus || x?.isExactSus || x?.isEPOSusAmtDue)) ||
            (x.isAdjustDuedateEdit && x.agreementType == CONSTANTS.EPO_RENT && x?.acceptEpoFlag == 0 && !(x?.isOverSus || x?.isExactSus || x?.isEPOSusAmtDue)) ||
            (x.isEditScheduleEdited && x.agreementType == CONSTANTS.EPO_RENT && x?.acceptEpoFlag == 0 && !(x?.isOverSus || x?.isExactSus || x?.isEPOSusAmtDue))) &&
          x.isNoteAgreement == CONSTANTS.STATUS_YES
      );
      const clubFlag = agreementDetails.filter(
        (x: AgreementDetails) => x.agreementType == CONSTANTS.CLUB && x.selected
      );
      if (
        clubInformation[0] == undefined
          ? false
          : (clubInformation[0].clubDocumentSignStatus == null ||
            clubInformation[0].clubDocumentSignStatus == CONSTANTS.UK) &&
          clubInformation[0].originAgreementId == null &&
          clubFlag.length > 0 &&
          clubInformation[0]?.numberOfPaymentsMade == CONSTANTS.ZERO_NUMBER &&
          receiptResultArray?.totalFileList?.some((x) => x.storeNumber == clubInformation[0]?.storeNumber && x.isPaymentFailed == false)
      ) {
        customerDocGen({ isClub: true });
        setCustomerSignUsingField({
          ...customerSignUsingField,
          field: 'club',
          documentType: 'CLUB',
        });
        setcustomerSignaturePopup(true);
        setrecpt(false);
      } else if (receiptResultArray?.totalFileList?.some((x) => x.storeNumber == installmentInfo[0]?.storeNumber && x.isPaymentFailed == false) && installmentInfo?.length > CONSTANTS.ZERO_NUMBER) {
        installmentAgrSignFlow(installmentInfo);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const returnType = urlParams.get('type');
        if (returnType == 'RETURN' || returnType == 'CHRGOFF') {
          const returnAGRParamValue: string = urlParams.get('agreementId') ?? CONSTANTS.EMPTY_STRING;
          if (Number(returnAGRParamValue) > 0) {
            history.push({
              pathname: `/agreement/info/details/${customerId}/${returnAGRParamValue}`,
              search: `?type=${returnType}`,
            });
          }
        }
        else {
          redirectionFunc(false);
        }
      }
    } else {
      redirectionFunc(false);
    }
  };

  const transactioncompletedPopup = () => {
    return (
      <Grid
        item
        data-testid='transaction_completed'
        id='transatraction'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='transaction'
        aria-hidden='true'
      >
        <Grid item md={12} className={classes.textcenter}>
          <SuccessIcon></SuccessIcon>
          <Typography
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Transaction completed successfully
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            data-testid='transaction_completed_ok'
            color='primary'
            variant='contained'
            data-bs-dismiss='modal'
            data-bs-toggle='modal'
            onClick={() => {
              setrecpt(true);
              settran(false);
            }}
            data-bs-target='#delivery-receipt'
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const commitmentConfirmation = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter} data-testid='commitmentId'>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            Do you want to take recommitment ?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size='small'
            variant='outlined'
            color='primary'
            data-testid='commitment_no'
            isRounded={false}
            onClick={() => { redirectionFunc(true) }}
          >
            No
          </RACButton>
          <RACButton
            size='small'
            variant='contained'
            color='primary'
            onClick={() => {
              history.push({
                pathname: `/am/customer/${customerId}`,
                search: `?type=RECOMMIT`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const transantionFailedPopup = () => {
    return (
      <div>
        <Grid className={classes.textcenter} data-testid='transaction_failed'>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {cardPaymentFailedMessage == CONSTANTS.EMPTY_STRING ? (
            <Typography className={classes.racErrorFunction}>
              Transaction has been failed
            </Typography>
          ) : cardPaymentFailedMessage !== CONSTANTS.EMPTY_STRING ?
            <Typography className={classes.racErrorFunction}>
              {cardPaymentFailedMessage}
            </Typography> : null}

        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant='contained'
            color='primary'
            className={classes.me2}
            onClick={okClickUnavailable}
            data-testid='transaction_failed_ok'
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const extensionReceipt = () => {
    return receiptResultArray?.extentionArray?.map((value: ReceiptStatus, index: number) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`} >
            Extension Receipt Form:{CONSTANTS.STRING_WITH_SINGLE_SPACE}
            <a
              onClick={() => {
                setextIndex(index);
                setviewExtPopup(true);
              }}
              data-testid='extensionId'
              style={{
                textDecoration: 'underline',
                color: '#2179FE',
                cursor: 'pointer',
              }}
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };
  {/** cofReceipt() method used to render the UI of certi ficate of owner ship popup*/ }
  const cofReceipt = () => {

    return receiptResultArray.cooArray.map((value: ReceiptStatus, index: number) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Certificate of Ownership :
            <a
              onClick={() => {
                setcofIndex(index);
                setviewCOFPopup(true);
              }}
              style={{
                textDecoration: 'underline',
                color: '#2179FE',
                cursor: 'pointer',
              }}
              data-testid='cofId'
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };

  const Receiptpopup = () => {

    const receiptStatus = receiptResultArray?.totalFileList?.filter((x) => x?.documentType == 'PRCT'||x.documentType=='');

    return (
      <Grid
        item
        id='delivery-receipt'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='autopayconsent'
        aria-hidden='true'
        data-testid='receiptIdPopup'
      >
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          {/* apply racstrap here */}
          {receiptStatus?.map((obj, index) => {
            return (
              <Typography className={`${classes.colmd12} ${classes.mb2}`}>
                Payment Receipt - {receiptStatus[index].receiptID} :
                {(txtChange && receiptStatus?.length == 0)||(txtChange && receiptStatus[index]?.documentURL=='') ? (
                  <CircularProgress className={classes.receiptLoader} />
                ) : receiptStatus != undefined && !receiptStatus[index]?.isLoading && !receiptStatus[index]?.isPaymentFailed ? (
                  <a
                    onClick={() => {
                      setviewReceiptPopup(true)
                      setChoosenReceiptIndex(index)
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: '#2179FE',
                      cursor: 'pointer',
                    }}
                    data-testid='viewReceipt'
                    className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                  >
                    View
                  </a>
                ) : (
                  'Generating receipt failed Please contact IT Help Desk'
                )}
              </Typography>
            );
          })}

          {receiptResultArray?.cooArray?.length > CONSTANTS.ZERO_NUMBER ? (
            <>
              <Typography
                className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
              >
                Please load the Certificate of Ownership into Tray1 with face up
                and the top of the form facing front.
              </Typography>
              {cofReceipt()}
            </>
          ) : null}
          {receiptResultArray?.extentionArray?.length > CONSTANTS.ZERO_NUMBER ? extensionReceipt() : null}
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
            <RACButton
              className={classes.mx1}
              color='primary'
              variant='outlined'
              onClick={() => {
                if (featureFlags.RACExchange == CONSTANTS.ONE_STRING && Number(exchangeAgreementId) != 0 && Number(agreementId) != 0) {
                  postAgreementActivity({
                    agreementActivities: [{
                      agreementId: agreementId,
                      activityDescription: `Agreement Exchange Started`,
                      agreementActivityType: "AGMXNGSRT",
                    }]
                  });
                  const enrolledAgreement = exchangedAgreementArray.filter((x: ExchangeAgreementArray) => x.childrenAgreementId == agreementId && x.isAutoPayEnrolled)
                  if (enrolledAgreement?.length > 0 && Number(agreementId) != 0) {

                    setAutoPayConfirmationPopup(true);
                  }
                  else {
                    setRacExchangeAutoPayPopup(true);
                  }
                }
                else {
                  ReceiptOk();
                }
              }}
              data-testid='Receipt_okId'
              style={{ marginTop: '20px' }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const transactionResultHeader = () => (
    <>
      <RACTableCell style={{ textAlign: 'center' }}>Store Number</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>
        Transaction Status
      </RACTableCell>
    </>
  );
  const transactionResultBody = () => (
    <>
      {transactionResultArray?.map((obj: PaymentAPIResult, index: number) => {
        return (
          <RACTableRow key={index} backgroundColor='white'>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.storeNumber}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.transactionStatus == 'Passed' ? (
                <TransactionSuccessIcon className={classes.receiptLoader} />
              ) : (
                <FailIcon />
              )}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );
  const buildPaymentStatus = () => {
    return (
      <Grid item md={12} data-testid='multi-store'>
        <Grid item md={12} className={classes.textCenter}>
          <RACTable
            className={`${classes.racGrid} ${classes.mb32}`}
            renderTableHead={transactionResultHeader}
            renderTableContent={transactionResultBody}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size='small'
            variant='contained'
            color='primary'
            data-testid='payment_status_ok'
            onClick={() => {
              setShowPaymentStatus(false);
              setShowReceiptStatus(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const spinnerpopup = () => {
    return (
      <Grid
        item
        data-testid='spinpart'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='transaction'
        aria-hidden='true'
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color='blue' loading={load} size={40} />
          <Typography className='row justify-content-center popup-text'>
            Transaction In Progress
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant='h6' className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const print = () => {
    eventFrame.current?.contentWindow?.print();
  };
  const okClickUnavailable = () => {
    setIsAmountMismatch(false);
    setTransactionFail(false);
    // setepayFail(false);
    window.location.reload();
  };
  const renderAmountMismatch = () => {
    return (
      <div>
        <Grid className={classes.textcenter} data-testid='amount_matchId'>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            There was an error processing your request. Please use ‘Report an
            Issue’ quick link under help, or Self Service portal to report this
            issue, and someone from the support team will promptly assist you
            with this request.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant='contained'
            color='primary'
            className={classes.me2}
            onClick={okClickUnavailable}
            data-testid='amount_MisMatch_ok'
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  /**The isExceptionProvided() method is used to check whether free time or EPO discount is 
  provided for any agreement
   */
  const isExceptionProvided = () => {
    let selectedEPOAgreements: AgreementDetails[] = agreementDetails.filter(
      (x) =>
        (x.selected &&
          x.storeNumber ==
          storeNumber &&
          x.otherIncomeType == CONSTANTS.EMPTY_STRING &&
          x.acceptEpoFlag == CONSTANTS.ONE_NUMBER &&
          x.isAcceptEPODiscounts?.epoDiscount &&
          x.isAcceptEPODiscounts?.epoDiscount != CONSTANTS.ZREO_TWO_DECIMAL_STRING) ||
        (x.isAcceptEPODiscounts?.pastDueDiscount &&
          x.isAcceptEPODiscounts?.pastDueDiscount != CONSTANTS.ZREO_TWO_DECIMAL_STRING) ||
        (x.isAcceptEPODiscounts?.noteDiscount &&
          x.isAcceptEPODiscounts?.noteDiscount != CONSTANTS.ZREO_TWO_DECIMAL_STRING)
    );
    let selectedFreeTimeAgreements: AgreementDetails[] = agreementDetails.filter(
      (x) =>
        x.selected && x.freeTime && x.freeTime.freeTimeType != CONSTANTS.PROMO_FT
    );
    let storeExceptionPayloadArr: ExceptionTypeArray[] = [];
    if (
      selectedEPOAgreements.length > CONSTANTS.ZERO_NUMBER ||
      selectedFreeTimeAgreements.length > CONSTANTS.ZERO_NUMBER
    ) {
      if (selectedEPOAgreements.length > CONSTANTS.ZERO_NUMBER) {
        storeExceptionPayloadArr.push({ type: CONSTANTS.EPD, isOpen: true });
      }
      if (selectedFreeTimeAgreements.length > CONSTANTS.ZERO_NUMBER) {
        storeExceptionPayloadArr.push({ type: CONSTANTS.REX, isOpen: true });
      }
    }
    setExceptionTypeArr(storeExceptionPayloadArr);
  };

  /** The calculatePayment() method is triggered once the two factor authentication is completed*/
  const calculatePayment = () => {
    if (exceptionTypeArr.length > CONSTANTS.ZERO_NUMBER) {
      storeExceptionInvoke();
    }
    else {
      setload(true)
      invokePaymentAPI();
    }
  }

  /** The storeExceptionCompletedFn() is invoked when the continue button is clicked on the store exception popup 
  this method is invoked */
  const storeExceptionCompletedFn = (response) => {
    let arrayException: ExceptionTypeArray[] = [];
    if (response.some((x) => x.exceptionTypeRefCode == CONSTANTS.EPD)) {
      arrayException = exceptionTypeArr.filter((x) => { x.type != CONSTANTS.EPD })
      // setExceptionTypeArr(exceptionTypeArr)
    }
    if (response.some((x) => x.exceptionTypeRefCode == CONSTANTS.REX)) {
      arrayException = exceptionTypeArr.filter((x) => { x.type != CONSTANTS.REX })
    }
    exceptionTypeArr = arrayException
    setExceptionTypeArr(exceptionTypeArr);
    storeExceptionRequest = response;
    setStoreExceptionRequest(storeExceptionRequest);
    calculatePayment();
  }
  /** The storeExceptionPayloadFormation() method is invoked to form the payload to make the store exception 
    API call 
  */
  const storeExceptionPayloadFormation = (storeExceptionPayloadFormation: TakePaymentRequest, resultOfDoct) => {
    const payload: StoreExceptionPayload[] = [];
    const epoException: StoreExceptionPayload[] = storeExceptionRequest.filter((x) => x?.exceptionTypeRefCode == CONSTANTS.EPD);
    const freeDaysExtension: StoreExceptionPayload[] = storeExceptionRequest.filter((x) => x?.exceptionTypeRefCode == CONSTANTS.REX);
    if (epoException?.length > CONSTANTS.ZERO_NUMBER) {
      epoException.map((obj) => {
        obj.identifierId = Number(resultOfDoct?.receiptId)
      })
    }
    const exceededFreeTime = storeExceptionPayloadFormation?.paymentInfo?.[0]?.exceededFTAmount;
    storeExceptionPayloadFormation?.paymentInfo?.[0]?.agreementPayment?.map((obj: AgreementPaymentEntity) => {
      if (obj?.freeTime != undefined && obj.freeTime?.type != CONSTANTS.PROMO_FT)
        payload.push({
          identifierId: Number(resultOfDoct[0]?.receiptID),
          identifierType: CONSTANTS.RECEIPT.toUpperCase(),
          storeNumber: freeDaysExtension[0]?.storeNumber,
          exceptionTypeRefCode: exceededFreeTime && exceededFreeTime > 0 ? 'CFT' : CONSTANTS.REX,
          exceptionText: freeDaysExtension[0]?.exceptionText,
          customerId: Number(customerId),
          agreementId: Number(obj.agreementId),
          approverId: exceededFreeTime && exceededFreeTime > 0 ? coWorkerDetails.employeeId : undefined
        })
    })
    return { storeException: [...payload, ...epoException] }

  }

  /**
   * This function is used to handle the modal name EPP Customer
   * @returns 
   * 
   */
  const getModuleName = () => {
    return (customerInfo?.isEppCustomer == CONSTANTS.STATUS_YES ?
      CONSTANTS.SLA_MODULE_NAME_RET_EXT_EPP : CONSTANTS.SLA_MODULE_NAME_ACCEPT_PAYMENT);
  }

  const storeExceptionInvoke = () => {
    if (exceptionTypeArr.some((x) => x.isOpen)) {
      setStoreExceptionEnable(true);
    }
    else {
      setStoreExceptionEnable(false);
    }
  }
  const close = () => {
    settran(false);
    setrecpt(false);
  };

  const renderReceiptPopup = ({
    isOtherStore,
    receiptIndex,
    urlArray,
    isExtension,
    isCOwnerShip,
  }) => {


    const handleClose = () => {
      if (isExtension) {
        setviewExtPopup(false);
      } else {
        setviewReceiptPopup(false);
        setviewCOFPopup(false);
      }
    };
    return (
      <Grid
        item
        data-testid='spinpart'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='transaction'
        aria-hidden='true'
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
          data-testid='receiptIframe'
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width='100%'
            height='750px'
            src={`${urlArray[receiptIndex]?.documentURL}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant='outlined'
            color='secondary'
            data-testid='receipt_close'
            className={classes.me2}
            onClick={handleClose}
          >
            Cancel
          </RACButton>
          <RACButton variant='contained' color='primary' onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const receiptResultHeader = () => (
    <>
      <RACTableCell style={{ textAlign: 'center' }}>Store Number</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>Receipt Type</RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>
        Receipt Status
      </RACTableCell>
    </>
  );

  const receiptResultBody = () => (
    <>
      {receiptResultArray?.totalFileList.map((obj: ReceiptStatus, index: number) => {
        return (
          <RACTableRow key={index} backgroundColor='white'>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.storeNumber}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'center' }}>
              {obj.documentType == CONSTANTS.RECEIPT_TYPE_PRCT
                ? CONSTANTS.PAYMENT_RECEIPT
                : obj.documentType == CONSTANTS.RECEIPT_TYPE_COOS
                  ? CONSTANTS.CERTIFICATE_OF_OWNERSHIP
                  : obj.documentType == CONSTANTS.RECEIPT_TYPE_EXTRF
                    ? CONSTANTS.EXTENTION_RECEIPT
                    : CONSTANTS.HYPHEN}
            </RACTableCell>
            {obj.isLoading ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.receiptLoader} />
              </RACTableCell>
            ) : obj.isPaymentFailed ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <FailIcon />
              </RACTableCell>
            ) : obj.documentURL !== CONSTANTS.EMPTY_STRING ? (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <a
                  onClick={() => {
                    setviewReceiptPopup(true);
                    setChoosenReceiptIndex(index);
                    // choosenReceiptIndex = index;
                  }}
                  data-testid='viewReceiptId'
                  style={{
                    textDecoration: 'underline',
                    color: '#2179FE',
                    cursor: 'pointer',
                  }}
                  className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                >
                  View
                </a>
              </RACTableCell>
            ) : (
              <RACTableCell key={index} style={{ textAlign: 'center' }}>
                <a
                  onClick={() => {
                    setreceiptResultArray({ ...receiptResultArray, totalFileList: initialReceiptStatus ?? [] });
                    setShowReceiptStatus(true);
                    invokeReceiptParallel(finalPaymentResult);
                  }}
                  style={{
                    textDecoration: 'underline',
                    color: '#2179FE',
                    cursor: 'pointer',
                  }}
                  className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                >
                  Retry
                </a>
              </RACTableCell>
            )}
          </RACTableRow>
        );
      })}
    </>
  );

  const buildReceiptStatus = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter} data-testid='showReceiptStatusId'>
          <RACTable
            className={`${classes.racGrid} ${classes.mb32}`}
            renderTableHead={receiptResultHeader}
            renderTableContent={receiptResultBody}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size='small'
            variant='contained'
            color='primary'
            data-testid='showReceiptStatus_ok'
            onClick={() => {
              setShowReceiptStatus(false);
              ReceiptOk();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {secondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => { setTwoFactorCancelClick(false); setSecondFactorOpen(false); }}
          setTwoFactorCompleted={() => {
            setSecondFactorOpen(false);
            calculatePayment();
          }}
          moduleName={getModuleName()}
          //   setEmployeeDetails={setEmployeeDetails}
          //   storeAccessResponse={profileAccResp}
          CurrentRole={coWorkerDetails.role}
        />
      ) : null}
      {freeTimeAuthPopup.secondFactor ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setFreeTimeAuthPopup({
              ...freeTimeAuthPopup,
              popup: false,
              secondFactor: false,
            });
            setProceedButtonLoader(false);
          }}
          setTwoFactorCompleted={() => {
            setFreeTimeAuthPopup({
              ...freeTimeAuthPopup,
              popup: false,
              secondFactor: false,
            });
            setProceedButtonLoader(false);
            setSecondFactorOpen(true);
            // calculatePayment()
          }}
          moduleName='CappedFreeTime'
          //   setEmployeeDetails={setEmployeeDetails}
          //   storeAccessResponse={profileAccResp}
          CurrentRole={coWorkerDetails.role}
        />
      ) : null}
      {customerSignaturePopup ? (
        <CustomerSignature
          customerSignUsingField={customerSignUsingField.field}
          customerSignUsingModule='payment'
          identifier={
            customerSignUsingField.field == CONSTANTS.CLUB.toLowerCase()
              ? Number(parseInt(customerId))
              : Number(pifAgreementID)
          }
          customersArr={getCustDetail(customerInfo)}
          manualSignOptions={CONSTANTS.ManualSignOptions}
          documentTypeArr={[customerSignUsingField.documentType]}
          customerSignatureCompletedFn={() => redirectionFunc(false)}
          customerSignatureCanceledFn={() => redirectionFunc(false)}
          documentGenerateCompleted={clubSignFlow}
        />
      ) : null}
      <RACModalCard
        isOpen={recpt}
        maxWidth='xs'
        onClose={() => close()}
        closeIcon={false}>
        {Receiptpopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={isAmountMismatch}
        maxWidth='xs'
        borderRadius={'20px !important'}
      >
        {renderAmountMismatch()}
      </RACModalCard>

      <RACModalCard
        isOpen={transactionFail}
        maxWidth='xs'
      >
        {transantionFailedPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={tran}
        maxWidth='xs'
        onClose={() => close()}
        closeIcon={false}>
        {transactioncompletedPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={showReceiptStatus}
        maxWidth='sm'
        title={CONSTANTS.RECEIPT_STATUS}
        borderRadius={CONSTANTS.BORDER_RADIUS}
      >
        {buildReceiptStatus()}
      </RACModalCard>

      <RACModalCard
        isOpen={viewReceiptPopup}
        className={classes.customModal}
        maxWidth='lg'
        title={CONSTANTS.RECEIPT_UPPER_CASE}
        closeIcon={true}
        onClose={() => setviewReceiptPopup(false)}>
        {renderReceiptPopup({
          isOtherStore: multiStoreSelected,
          receiptIndex: choosenReceiptIndex,
          urlArray: receiptResultArray?.totalFileList ?? [],
          isExtension: false,
          isCOwnerShip: false,
        })}

      </RACModalCard>
      <RACModalCard
        isOpen={showPaymentStatus}
        maxWidth='sm'
        title={'Transaction Status'}
        borderRadius={CONSTANTS.BORDER_RADIUS}
      >
        {buildPaymentStatus()}
      </RACModalCard>


      <RACModalCard
        isOpen={load}
        maxWidth='xs'
        closeIcon={false}
      >
        {spinnerpopup()}
      </RACModalCard>


      <RACButton
        color='primary'
        variant='contained'
        data-testid='accept_Payment_id'
        className={classes.ms2}
        style={{ height: '42.5px' }}
        disabled={Object.values(paymentDisable).some((x) => x ? true : false) || !agreementDetails.some((x) => x.selected)}
        onClick={() => acceptPaymentOnclick(true)}
      >
        Accept Payment
        {/* customer3 */}
      </RACButton>

      <RACModalCard
        isOpen={openSuspenseWithdraw}
        maxWidth='sm'
        borderRadius={CONSTANTS.BORDER_RADIUS}
      // children={buildSuspenseWithdraw()}
      >
        {buildSuspenseWithdraw()}
      </RACModalCard>


      {freeTimeAuthPopup.popup ? (
        <RACModalCard
          isOpen={freeTimeAuthPopup.popup}
          closeIcon={false}
          borderRadius={CONSTANTS.BORDER_RADIUS}
          // onClose={() => setFreeTimeAuthPopup({ ...freeTimeAuthPopup, popup: false })}
          maxWidth={'xs'}
        // children={freeTimeAuthPopupContent()}
        >
          {freeTimeAuthPopupContent()}
        </RACModalCard>

      ) : null}

      {receiptResultArray != undefined && receiptResultArray?.extentionArray?.length > CONSTANTS.ZERO_NUMBER ? (
        <>
          <RACModalCard
            isOpen={viewExtPopup}
            className={classes.customModal}
            maxWidth='lg'
            title={'Extension Receipt'}
            closeIcon={true}
            onClose={() => setviewExtPopup(false)}>
            {renderReceiptPopup({
              isOtherStore: false,
              receiptIndex: extIndex,
              urlArray: receiptResultArray?.extentionArray ?? [],
              isExtension: true,
              isCOwnerShip: false,
            })}
          </RACModalCard>
        </>
      ) : null}
      <RACModalCard
        isOpen={openCommitmentConfirmation}
        maxWidth='sm'
        borderRadius={CONSTANTS.BORDER_RADIUS}
      >
        {commitmentConfirmation()}
      </RACModalCard>

      {StoreExceptionEnable ? (<StoreException
        isopen={StoreExceptionEnable}
        setStoreExceptionEnable={setStoreExceptionEnable}
        refCode={exceptionTypeArr[0].type}
        identifierType={'RECEIPT'}
        exceptionDescscription={exceptionTypeArr[0].type == CONSTANTS.REX ? CONSTANTS.RENTAL_EXTENTION : null}
        storeExceptionCancelled={() => {
          setStoreExceptionEnable(false);
          setStoreExceptionRequest([])
        }}
        storeExceptionCompleted={(res) => { storeExceptionCompletedFn(res) }}
      />) : null}
      {receiptResultArray != undefined && receiptResultArray?.cooArray?.length > 0 ? (
        <>
          <RACModalCard
            isOpen={viewCOFPopup}
            className={classes.customModal}
            maxWidth='lg'
            title={'Certificate of Ownership'}
            closeIcon={true}
            onClose={() => setviewCOFPopup(false)}>
            {renderReceiptPopup({
              isOtherStore: false,
              receiptIndex: cofIndex,
              urlArray: receiptResultArray?.cooArray ?? [],
              isExtension: false,
              isCOwnerShip: true,
            })}
          </RACModalCard>
        </>
      ) : null}

      <RACModalCard
        isOpen={sessionExpiredPopupFlag}
        maxWidth='xs'
        borderRadius={CONSTANTS.BORDER_RADIUS}
      >
        {sessionExpiredReloadPopup()}
      </RACModalCard>
      {autoPayConfirmationPopup ? (
        <RACModalCard
          isOpen={autoPayConfirmationPopup}
          closeIcon={false}
          borderRadius={"25px !important"}
          onClose={() => {
            setAutoPayConfirmationPopup(false);
            history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
          }}
          maxWidth={"sm"}
        // children={openAutoPayPopup()}
        >
          {openAutoPayPopup()}
        </RACModalCard>
      ) : null}

      {
        racExchangeAutoPayPopup && (
          <RACModalCard
            isOpen={racExchangeAutoPayPopup}
            maxWidth="sm"
            onClose={() => {
              setRacExchangeAutoPayPopup(false),
                history.push(`/agreement/rental/delivery/${customerId}/${agreementId}?exagreementid=${exchangeAgreementId}`)
            }}
            closeIcon={true}
          >
            <Autopay
              cardPopup={racExchangeAutoPayPopup}
              setCardPopup={(res) => setRacExchangeAutoPayPopup(res)}
              unEnrollAutopay={false}
              manageCard={false}
              racExchange={true}
            ></Autopay>
          </RACModalCard>
        )
      }
    </>
  );
}
